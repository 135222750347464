<template>
  <ion-page>
    <wordsHeader title="Info" />
    <ion-content :fullscreen="true" padding v-if="!isStartpage">
      <div v-if="notification" class="textblock" id="noti_alert">
        {{ notification }}
      </div>
      <ion-spinner
        v-if="loading"
        name="circles"
        id="loadspinner"
        color="primary"
      ></ion-spinner>
      <ion-card v-else>
        <small class="authorcaption">This quote was submitted by:</small>
        <ion-avatar class="image-center">
          <img v-if="userdata.avatar" :src="userdata.avatar" />
        </ion-avatar>
        <ion-card-header>
          <ion-card-title class="ion-text-center">{{
            userdata.username
          }}</ion-card-title>
        </ion-card-header>
        <ion-card-content class="ion-text-center abouttext">
          <p class="about">
            {{ userdata.about }}
          </p>
          <p class="userlink" v-if="userdata.userlink">
            <small class="authorcaption">Userlink</small>
            <br />
            <a
              :title="userdata.userlink"
              :href="userdata.userlink"
              target="_blank"
              ><img src="/assets/img/grid-globe-link.png"
            /></a>
          </p>
        </ion-card-content>
      </ion-card>
    </ion-content>
    <ion-content v-else>
      <ion-card>
        <img src="" />
        <ion-card-header>
          <ion-card-title class="ion-text-center"> ABOUT </ion-card-title>
        </ion-card-header>
        <ion-card-content class="ion-text-center content">
          <div class="infoimage"></div>
          <div class="abouttext">
            <p class="about">
              This app is dedicated to spiritual quotes and life wisdom.<br /><br />
              You will find many impulses that will guide you through the day.
            </p>
          </div>
          <div class="aboutcopyright">
            <div class="metainfo">
              <p>Version: 1.06</p>
              <p>&nbsp;</p>
              <p>
                Contact us:
                <a href="mailto:info@everwords.ozona.ch">info@everwords.ch</a>
              </p>
            </div>
            &copy; 2024
            <a href="http://www.lxmedia.ch" target="_blank">LX Multimedia</a>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
const axios = require("axios").default;
import wordsHeader from "@/components/Header.vue";
import {
  IonPage,
  IonContent,
  IonAvatar,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonSpinner,
} from "@ionic/vue";

export default {
  name: "Info",
  components: {
    IonContent,
    IonPage,
    wordsHeader,
    IonAvatar,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonSpinner,
  },
  data: () => {
    return {
      userdata: {},
      profileURL: "/api/user",
      notification: "",
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData: function() {
      if (this.isStartpage) return;
      this.loading = true;
      const userid = this.$store.state.postuserid;
      axios
        .get(this.profileURL + "/" + userid)
        .then((response) => {
          this.userdata = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.notification = error.response.data.error;
        });
    },
  },
  computed: {
    isStartpage() {
      return this.$store.state.isStartpage;
    },
  },
};
</script>

<style scoped>
#loadspinner {
  position: absolute;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
  display: block;
}

#noti_alert {
  margin: 20px auto;
  text-align: center;
  font-family: "Ubuntu Mono", monospace;
  min-height: 30px;
}

.textblock {
  margin: 0 auto;
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.image-center {
  margin: 0 auto;
  margin-top: 30px;
}

ion-card {
  padding: 0px;
}

ion-card * {
  border-radius: 15px;
}

.abouttext p.about {
  font-size: 22px;
  margin: 20px 0px 20px 0px;
  background: rgba(28, 82, 146, 0.1);
  padding: 20px;
}

.aboutcopyright {
  font-weight: normal;
  font-size: 16px;
  font-family: "Ubuntu Mono", monospace;
}
.aboutcopyright a {
  font-weight: normal;
  font-size: 16px;
  font-family: "Ubuntu Mono", monospace !important;
}

.content img {
  margin: 20px 0px 60px 0px;
}

.authorcaption {
  margin: 0 auto;
  text-align: center;
  display: block;
  margin-top: 30px;
  font-family: "Ubuntu Mono", monospace !important;
}

.userlink a {
  font-size: 16px;
  font-family: "Ubuntu Mono", monospace !important;
}
.userlink img {
  width: 30px;
}

.infoimage {
  background: url("/assets/img/info.jpg");
  background-size: cover;
  height: 300px;
}

@media screen and (max-width: 800px) {
  .infoimage {
    height: 150px;
  }
}

.metainfo {
  background: rgba(255, 255, 255, 0.025);
  text-align: center;
  margin: 0px 0px 50px 0px;
  padding: 20px;
}

.metainfo a {
  font-size: 14px;
  font-family: unset !important;
  display: block;
}
</style>
