<template>
  <ion-page>
    <wordsHeader title="Password Recovery" />
    <ion-content :fullscreen="true" padding>
      <div
        style="height: 100%; width: 100%; display: grid; place-items: center;"
      >
        <div style="width: 100%">
          <div v-if="notification" class="textblock" id="noti_alert">
            {{ notification }}
          </div>
          <form ref="form" @submit.prevent="reset()">
            <ion-grid>
              <ion-row color="primary" justify-content-center>
                <ion-col
                  align-self-center
                  size-md="6"
                  size-lg="6"
                  offset-lg="3"
                  offset-md="3"
                  size-xs="12"
                >
                  <div padding>
                    <div class="item">
                      <ion-input
                        name="email"
                        type="email"
                        placeholder="Your email address"
                        required
                        v-model="formdata.email"
                        @keyup.enter="submitKeyEnter()"
                      ></ion-input>
                    </div>
                  </div>
                  <div padding>
                    <div class="item">
                      <ion-input
                        name="password"
                        type="password"
                        placeholder="Type new password"
                        required
                        v-model="formdata.password"
                        @keyup.enter="submitKeyEnter()"
                      ></ion-input>
                    </div>
                  </div>
                  <div>
                    <ion-button
                      style="margin: 15px 0"
                      size="large"
                      type="submit"
                      expand="block"
                      color="light"
                      >Update your password</ion-button
                    >
                  </div>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col
                  align-self-center
                  size-md="6"
                  size-lg="6"
                  offset-lg="3"
                  offset-md="3"
                  size-xs="12"
                >
                  <div
                    class="textblock"
                    style="width: 100%; display: flex; flex-direction: row; gap: 10px;"
                  >
                    <router-link style="width: 100%;" to="/login"
                      ><ion-button style="width: 100%;">Login</ion-button>
                    </router-link>
                    <router-link to="/register" style="width: 100%;"
                      ><ion-button style="width: 100%;">Register</ion-button>
                    </router-link>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
const axios = require("axios").default;
import wordsHeader from "@/components/Header.vue";

import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonInput,
} from "@ionic/vue";

export default {
  name: "Password Reset",
  data: () => {
    return {
      GetPWResetUrl: "/api/password/reset",
      notification: "",
      formdata: {},
    };
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonInput,
    IonContent,
    IonPage,
    wordsHeader,
  },
  mounted() {
    this.$store.state.isStartpage = true;
    this.formdata.token = this.$route.params.token;
  },
  methods: {
    reset: function() {
      axios
        .post(this.GetPWResetUrl, this.formdata)
        .then((response) => {
          this.notification = response.data.success.message;
        })
        .catch((error) => {
          this.notification = error.response.data.error.message;
        });
    },
    submitKeyEnter: function() {
      this.$refs.form.requestSubmit();
    },
  },
};
</script>

<style scoped>
#noti_alert {
  margin: 20px auto;
  text-align: center;
  font-family: "Ubuntu Mono", monospace;
  min-height: 30px;
}

.textblock {
  margin: 0 auto;
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

ion-input {
  font-family: "Ubuntu Mono", monospace !important;
}

div.item {
  padding: 10px 0;
}

div.item ion-input,
div.item input {
  --border-radius: 4px; /* Adds rounded corners */
  border-radius: 4px;
  background-color: #222428;
  overflow: hidden;
}
</style>
