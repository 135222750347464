<template>
  <ion-page>
    <wordsHeader title="Words" />
    {{ tmsg }}
    <!-- Circles -->
    <ion-spinner
      v-if="loading && !showAd"
      name="circles"
      id="loadspinner"
      color="primary"
    ></ion-spinner>
    <!-- Ads -->
    <div v-if="showAd"></div>
    <!-- Quote -->
    <ion-card v-else-if="!loading" id="wimagecard">
      <div id="wordscontent" v-if="!isStartpage">{{ content }}</div>
      <div
        id="wimageholder"
        v-if="isStartpage"
        :alt="author"
        :style="{
          backgroundImage: 'url(' + imgsrchome + ')',
        }"
      />
      <div
        v-else
        id="wimageholder"
        :alt="author"
        :style="{
          backgroundImage: 'url(' + background + ')',
        }"
      />
    </ion-card>
    <!-- Quote Footer -->
    <div v-if="!showAd && !loading && !isStartpage" id="copyright">
      <a :href="photoURL" target="_blank"
        ><img src="/assets/img/pexels-logo.png"
      /></a>
      <!--
            <a v-if="author" class="author" target="_blank" :href="photographerUrl"
        >{{ author.substring(0, 10) }}...</a
      > -->
      <a
        :title="quotelink"
        v-if="quotelink"
        :href="quotelink"
        target="_blank"
        class="quotelink"
      >
        Link
      </a>
    </div>
    <ion-footer no-border>
      <ion-toolbar>
        <ion-button
          id="getButton"
          color="light"
          @click="nextCard()"
          expand="full"
          ><ion-icon
            size="small"
            slot="start"
            src="/assets/arrow-redo-outline.svg"
          ></ion-icon>
          &nbsp;NEXT&nbsp;<ion-icon
            size="small"
            slot="end"
            src="/assets/arrow-redo-outline.svg"
          ></ion-icon
        ></ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import wordsHeader from "@/components/Header.vue";
import * as allIcons from "ionicons/icons";

import {
  AdMob,
  BannerAdSize,
  BannerAdPosition,
  BannerAdPluginEvents,
} from "@capacitor-community/admob";

const axios = require("axios").default;
const token = "563492ad6f91700001000001dff60917b0aa41b183db16535ab01405";

import {
  IonIcon,
  IonPage,
  IonCard,
  IonButton,
  IonToolbar,
  IonFooter,
  IonSpinner,
  isPlatform,
} from "@ionic/vue";

export default defineComponent({
  components: {
    IonIcon,
    IonPage,
    IonCard,
    IonButton,
    IonToolbar,
    IonFooter,
    IonSpinner,
    wordsHeader,
  },
  data: () => {
    return {
      i: allIcons,
      background: "",
      imagefolder: "/assets/img/backgrounds/",
      imgsrchome: "/assets/img/background.jpg",
      imgsrc: "",
      author: "",
      totalResults: 0,
      nextPage: 0,
      content: "",
      photoURL: "https://www.pexels.com/",
      photographerUrl: "",
      quotelink: "",
      adId: {
        android: "ca-app-pub-6857639280700739/3090101533",
        //Test: android: "ca-app-pub-3940256099942544/6300978111",
        ios: "xxx",
      },
      loading: false,
      tmsg: "",
    };
  },
  created() {
    if (this.isCapacitor) {
      AdMob.initialize({
        requestTrackingAuthorization: true,
        initializeForTesting: false,
      });
      AdMob.addListener(BannerAdPluginEvents.FailedToLoad, () => {
        // Subscribe Banner Event Listener
        console.log("Ads loading failed");
      });
      AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
        // Subscribe Banner Event Listener
        console.log("Ads loaded");
      });
      AdMob.addListener(BannerAdPluginEvents.SizeChanged, (size) => {
        // Subscribe Change Banner Size
        console.log("Ads Size changed", size);
      });
    }
  },
  mounted() {
    this.$store.state.isStartpage = true;
    this.$store.state.slidecount = 0;

    this.$store.state.settings.lang = localStorage.getItem("wdslg")
      ? localStorage.getItem("wdslg")
      : "de";
    axios
      .get(this.ImageQueryUrl, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        this.totalResults = response.data.total_results;
        this.calcNextPage();
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  methods: {
    nextCard: function() {
      this.$store.state.isStartpage = false;
      this.loading = true;
      this.checkShowAd();
      if (this.showAd) {
        return;
      }

      this.getContent();
      this.getBackgroundImage();
      // axios
      //   .get(this.ImageQueryUrl, {
      //     headers: {
      //       Authorization: `${token}`,
      //     },
      //   })
      //   .then((response) => {
      //     this.imgsrc = response.data.photos[0].src.large2x;
      //     this.author = response.data.photos[0].photographer;
      //     this.totalResults = response.data.total_results;
      //     this.photoURL = response.data.photos[0].url;
      //     this.photographerUrl = response.data.photos[0].photographer_url;
      //     this.calcNextPage();
      //     this.getContent();
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
    },
    calcNextPage: function() {
      this.nextPage = Math.floor(Math.random() * this.totalResults + 1);
    },
    getContent: function() {
      axios
        .get(this.WordsQueryUrl)
        .then((response) => {
          this.content = response.data.content;
          this.quotelink = response.data.link;
          this.$store.commit("setPostUserID", response.data.user_id);
          this.loading = false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    checkShowAd: function() {
      if (this.isCapacitor) {
        this.$store.commit("slideIncrement");
        if (this.showAd) {
          this.loadAdMob();
          this.adActive = true;
        } else {
          if (this.adActive) {
            this.removeAdMob();
            this.adActive = false;
          }
        }
      }
    },
    loadAdMob: function() {
      const options = {
        adId: this.platformAdId,
        adSize: BannerAdSize.LARGE_BANNER,
        position: BannerAdPosition.CENTER,
        isTesting: false,
      };
      // Show Banner Ad
      AdMob.showBanner(options).then(
        (value) => {
          console.log("Success", value); // true
        },
        (error) => {
          this.tmsg = error;
          console.error("Error", error); // show error
        }
      );
    },
    removeAdMob: function() {
      // Destroy the banner, remove it from screen.
      AdMob.removeBanner().then(
        (value) => {
          console.log(value); // true
        },
        (error) => {
          console.error(error); // show error
        }
      );
    },
    getBackgroundImage: function() {
      const randomNumber = Math.floor(Math.random() * 1000) + 1; // Generate a number between 1 and 1000
      // Pad with leading zeros to make it 4 digits
      this.background =
        this.imagefolder + String(randomNumber).padStart(4, "0") + ".jpg";
    },
  },
  computed: {
    ImageQueryUrl() {
      return `https://api.pexels.com/v1/search/?page=${this.nextPage}&per_page=1&query=nature`;
    },
    WordsQueryUrl() {
      const l = this.$store.state.settings.lang;
      return "/api/quote/" + l + "/random";
    },
    showAd() {
      return (
        !(this.$store.state.slidecount % 5) &&
        this.$store.state.slidecount !== 0
      );
    },
    platformAdId() {
      return isPlatform("android") ? this.adId.android : this.adId.ios;
    },
    isCapacitor() {
      return isPlatform("capacitor");
    },
    isStartpage() {
      return this.$store.state.isStartpage;
    },
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      if (this.adActive) {
        this.$store.state.slidecount = 0;
        this.loading = false;
        this.removeAdMob();
      }
    },
  },
});
</script>

<style scoped>
#loadspinner {
  margin: 0 auto;
}

#copyright > a {
  font-family: "Ubuntu Mono", monospace;
}

#wimagecard {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 7px 5px 7px 5px;
}

#wimageholder {
  overflow: hidden;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}

#getButton {
  padding: 0 5px;
  font-size: 25px;
  margin: 0px;
  height: 45px;
  border-radius: 5px;
}

#wordscontent {
  position: absolute;
  text-align: center;
  margin: 0 auto;
  font-size: min(max(38px, 6vw), 55px);
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
  z-index: 99;
}

#copyright img {
  height: 100%;
  width: auto;
  padding: 9px;
}

#copyright a.author,
#copyright a.quotelink {
  font-size: 12px;
}

#copyright {
  display: flex;
  align-items: center;
  height: 30px;
}

#copyright > a:first-child {
  height: 100%;
}

#copyright > a.quotelink {
  flex-grow: 1;
  text-align: right;
  padding-right: 10px;
}

#copyright > a {
  text-transform: lowercase;
}
</style>
